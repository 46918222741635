/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Libraries
import parse from 'html-react-parser'
import styled from 'styled-components'

// Language
import { getLanguage } from 'services/language'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/TitleDefault'
import GravityForm from 'components/GravityForm'
import HeroDefault from 'components/elements/HeroDefault'

const FormContainer = styled.div`
  box-shadow: 0px 0px 4px #00000080;
  max-width: 600px;
  border-bottom-left-radius: 20px;
`

const Content = styled.div`
  & a {
    color: ${props => props.theme.color.text.contrast};

    &:hover {
      text-decoration: underline;
    }
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      content
      wordpressId: wordpress_id

      acf {
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        banner_title
      }

      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { wordpressId, path, content, yoastMeta, acf },
  },
}) => {
  const language = getLanguage()

  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />

      <HeroDefault
        image={acf.banner_image}
      />

      <section>
        <div className="container py-3 py-lg-5">
          <div className="row">
            <div className="col">
              <TitleDefault h1 className="ml-lg-5 pl-lg-5">{parse(acf.banner_title)}</TitleDefault>
            </div>
          </div>
        </div>
      </section>

      <div className="color-background-soft py-5">
        <div className="container">
          <div className="row">
            <Content className="col-lg-5">
              <ParseContent content={content} />
            </Content>
            <div className="col-lg-7 pl-lg-5">
              {wordpressId === 1253 && (
                <p className="font-size-xm"><strong>Laat je gegevens achter en wij nemen zo snel mogelijk contact met je op voor het inplannen van een afspraak!</strong></p>
              )}
              <FormContainer className="color-background-light">
                <div className="w-100 pb-5 pt-4 px-3 px-lg-5">
                  {wordpressId === 1253 ? (
                    <GravityForm id={10} />
                  ) : (
                    <GravityForm id={language === 'nl_NL' ? 2 : 2} />
                  )}
                </div>
              </FormContainer>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate
